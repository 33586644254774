import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  useIntl,
  Link,
  //FormattedMessage,
  //changeLocale,
} from "gatsby-plugin-react-intl";
import Slider from "react-slick";

const ComponentMain5 = ({ image, children }) => {
  const intl = useIntl();

  const [pos, setPos] = useState(1);
  //const isActiveBenefit = () => {
  //  return pos === 0 ? "active" : "";
  //};
  const isActiveScience = () => {
    return pos === 1 ? "active" : "";
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };
  return (
    <section className="sect sect_TreatmentCvd_iuh234 bg-white">
      <div className="container mb-0 pb-0">
        <div className="container-block flex-row position-relative bg-rot-important top-border-container">
          <div className="right-all-bg" />
          <div className="flex-column-12">
            <div className="flex-row top-border-wrap mb-8">
              {/* <div className="flex-column-4 px-4">
                <div
                  className={"white top-border " + isActiveBenefit()}
                  onClick={() => setPos(0)}
                >
                  The Benefits:
                </div>
              </div> */}
              <div className="flex-column-4 px-4">
                <div
                  className={"white top-border " + isActiveScience()}
                  onClick={() => setPos(1)}
                  onKeyDown={() => setPos(1)}
                  role = "button"
                  tabIndex={0}
                >
                  {intl.locale === "en" ? `Sciences:` : `Publikationen:`}
                </div>
              </div>
            </div>
          </div>
          <div className="container-block-imgContainer flex-column-6">
            <StaticImage
              src="../../images/products/non-medical/lumbar-type-slider-image-1@2x.png"
              alt="ImgTreatment"
              loading="eager"
              className="Imgblock01"
            />
          </div>
          <div className="container-block-txtContainer flex-column-6">
            <div className="px-6 py-0">
              {pos === 0 ? (
                <div>
                  <h2 className="h3">
                    The neuromuscular feedback therapy leads to a significant
                    gain in functional locomotion. Other effects which can be
                    more or less pronounced include: Reduction of neuropathic
                    pains, improved bladder and bowl functions, positive
                    improved spasticity symptoms, larger areas with skin
                    sensation resulting in a lower decubitus risk.
                  </h2>
                  <p>
                    • Functional improvement and increase in mobility
                    <br />
                    • Reduced need for assistive devices
                    <br />
                    • Increased walking speed
                    <br />
                    • Increased walking distance
                    <br />
                    • Development of musculature
                    <br />• Increased sensitivity
                  </p>
                  <div>
                    <Link to="#" className="link-simple">
                      Read
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="slide slide-single slide-rot">
                  <Slider {...settings}>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Hybrid assistive limb (HAL) for Care Support can reduce
  lumbar load in care deliveries`
                          : `Hybrid Assistive Limb (HAL) für die Pflegeunterstützung kann die Belastung der Lendenwirbelsäule bei Pflegediensten reduzieren.`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Successful Use of the Hybrid Assistive Limb for Care
  Support to Reduce Lumbar Load in a Simulated Patient
  Transfer - The HAL for Care Support significantly
  reduced subjective lumbar fatigue in a simulated patient
  transfer. Use of the HAL for Care Support may reduce the
  occurrence of low back disorders.`
                          : `Successful Use of the Hybrid Assistive Limb for Care Support to Reduce Lumbar Load in a Simulated Patient Transfer – HAL for Care Support reduzierte signifikant die subjektive Ermüdung der Lendenwirbelsäule bei einem simulierten Patiententransfer. Der Einsatz von HAL for Care Support kann das Auftreten von Rückenbeschwerden verringern.`}
                      </p>
                      <div>
                        <a
                          href="https://www.asianspinejournal.org/journal/view.php?doi=10.31616/asj.2019.0111"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Subjects were able to perform significantly more lifting
  cycles using HAL`
                          : `Die Probanden konnten mit HAL für Care Support deutlich mehr Hebevorgänge ausführen.`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Muscle Synergies During Repetitive Stoop Lifting With a
  Bioelectrically-Controlled Lumbar Support Exoskeleton -
  The results indicate that muscle coordination patterns
  are significantly changed, mainly in the timing
  coefficients of the synergies and marginally changed in
  synergy weights, when subjects are using an exoskeleton
  (HAL).`
                          : ` Muscle Synergies During Repetitive Stoop Lifting With a Bioelectrically-Controlled Lumbar Support Exoskeleton – Die Ergebnisse deuten darauf hin, dass sich die Muskelkoordinationsmuster signifikant verändern, hauptsächlich in den Zeitkoeffizienten der Synergien und geringfügig in den Synergielasten, wenn die Probanden ein Exoskelett (HAL) benutzen.`}
                      </p>
                      <div>
                        <a
                          href="https://www.frontiersin.org/articles/10.3389/fnhum.2019.00142/full"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Biofeedback exercise with HAL-Lumbar Type is a safe and
  promising treatment for frailty patients`
                          : `Biofeedback-Training mit HAL Lumbar Type ist eine sichere und vielversprechende Behandlung für gebrechliche Patienten.`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Biofeedback Core Exercise Using Hybrid Assistive Limb
  for Physical Frailty Patients With or Without
  Parkinson's Disease - Motor dysfunction in Parkinson's
  disease patients may be partly due to physical frailty,
  and biofeedback therapy with HAL-Lumbar Type is proposed
  as a treatment option. Immediate and sustained effects
  on patients who were refractory to conventional
  rehabilitation could provide evidence that changes in
  input to specific receptors by HAL- Lumbar Type
  contributes to activation of disused neuronal networks
  and amelioration of arthrogenic muscle inhibition.`
                          : ` Biofeedback Core Exercise Using Hybrid Assistive Limb for Physical Frailty Patients With or Without Parkinson's Disease – Die motorische Dysfunktion bei Parkinson-Patienten kann teilweise auf körperliche Gebrechlichkeit zurückzuführen sein. Die Biofeedback-Therapie mit HAL Lumbar-Type wird als Behandlungsoption vorgeschlagen. Die sofortige und nachhaltige Wirkung bei Patienten, die auf konventionelle Rehabilitationsmaßnahmen nicht ansprachen, könnte den Nachweis erbringen, dass Veränderungen des Inputs an spezifischen Rezeptoren durch HAL-Lumbaltyp zur Aktivierung stillgelegter neuronaler Netzwerke und zur Verbesserung der arthrogenen Muskelhemmung beitragen.`}
                      </p>
                      <div>
                        <a
                          href="https://www.frontiersin.org/articles/10.3389/fneur.2020.00215/full"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Significantly reduces cardiopulmonary burden during
  squat exercise in healthy subjects`
                          : `Erhebliche Verringerung der kardiopulmonalen Belastung während Kniebeugen bei gesunden Probanden`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `Effects of a lumbar-type hybrid assistive limb on
  cardiopulmonary burden during squat exercise in healthy
  subjects - The oxygen uptake (VO2) and the Borg Scale
  rating were significantly lower when HAL was used. The
  HAL significantly reduces cardiopulmonary burden during
  squat exercise`
                          : ` Effects of a lumbar-type hybrid assistive limb on cardiopulmonary burden during squat exercise in healthy subjects – Die Sauerstoffaufnahme (VO2) und die Bewertung auf der Borg-Skala waren signifikant niedriger, wenn HAL verwendet wurde. HAL reduziert signifikant die kardiopulmonale Belastung während der Kniebeuge.`}
                      </p>
                      <div>
                        <a
                          href="https://www.jocn-journal.com/article/S0967-5868(19)30591-0/fulltext"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Significantly decreased subjective lumbar fatigue`
                          : `Signifikante reduzierung der subjektive Ermüdung der Lendenwirbelsäule`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The hybrid assistive limb (HAL) for Care Support
  successfully reduced lumbar load in repetitive lifting
  movements – HAL- Lumbar Type can decrease lumbar load
  and improve the lifting performance during repetitive
  stoop lifting movements in healthy adults.`
                          : `The hybrid assistive limb (HAL) for Care Support successfully reduced lumbar load in repetitive lifting movements – HAL Lumbar Type kann die lumbale Belastung verringern und die Hebeleistung bei repetitiven Hebebewegungen in der Hocke bei gesunden Erwachsenen verbessern.`}
                      </p>
                      <div>
                        <a
                          href="https://www.jocn-journal.com/article/S0967-5868(18)30412-0/fulltext"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                    <div className="slide__link">
                      <h2 className="h3">
                        {intl.locale === "en"
                          ? `Significantly improved the snow-shoveling performance`
                          : `Signifikante Verbesserung der Schneeschaufelleistung`}
                      </h2>
                      <p>
                        {intl.locale === "en"
                          ? `The hybrid assisted limb (HAL) for Care Support, a
  motion assisting robot providing exoskeletal lumbar
  support, can potentially reduce lumbar load in
  repetitive snow-shoveling movements - The reduction of
  lumbar fatigue and improvement of snow-shoveling
  performance using HAL for Care Support were
  statistically significant.`
                          : `The hybrid assisted limb (HAL) for Care Support, a motion assisting robot providing exoskeletal lumbar support, can potentially reduce lumbar load in repetitive snow-shoveling movements – Die Verringerung der Ermüdung der Lendenwirbelsäule und die Verbesserung der Schneeschaufelleistung mit HAL for Care Support waren statistisch signifikant.`}
                      </p>
                      <div>
                        <a
                          href="https://www.jocn-journal.com/article/S0967-5868(17)31978-1/fulltext"
                          className="link-simple"
                        >
                          {intl.locale === "en" ? `Read` : `Lesen`}
                        </a>
                      </div>
                    </div>
                  </Slider>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ComponentMain5;
