import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
//import ComponentTextblock3 from "../../components/component-textblock-3";
//import Slider from "react-slick";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import ComponentProductNonMedical from "../../components/main/component-product-non-medical";

const ProductsNonMedicalDevice = ({ data, location }) => {
  const intl = useIntl();

  //const settings = {
  //  dots: true,
  //  infinite: false,
  //  speed: 500,
  //  //autoplay: true,
  //  autoplaySpeed: 4000,
  //  arrows: false,
  //  slidesToShow: 1,
  //  //centerPadding: '100px',
  //  focusOnSelect: true,
  //  //centerMode: true,
  //  variableWidth: true,
  //  responsive: [
  //    {
  //      breakpoint: 896,
  //      settings: {
  //        centerMode: false,
  //      },
  //    },
  //  ],
  //};

  return (
    <Layout location={location}>
      <Seo
        title={
          intl.locale === "en"
            ? `Assistive and regenerative technology with multi-usage`
            : `Vielfach einsetztbare, unterstützende und regenerative Technologie`
        }
        description={
          intl.locale === "en"
            ? `HAL Lumbar Type is assistive technology which mitigates risks of back pain as well as a neurological device to improve locomotive syndrome and frailty.`
            : `HAL Lumbar Type ist eine unterstützende Technologie, die Rückenschmerzen lindert und Schwächen der Bewegungsorgane, sowie Gebrechlichkeit entgegenwirkt. `
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/1I8Cq0lkF2YhjkHu748ZOw/4bc49e413f2dc7db683e421fb46871a0/E173.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-hellblau">
        <StaticImage
          src="../../images/products/non-medical/header-lumbar-type@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-none bg-sm-relative"
          alt=""
        />
        <StaticImage
          src="../../images/products/non-medical/header-lumbar-type-mobile@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-block bg-sm-relative"
          alt=""
        />
        <div className="container flex-row">
          <div className="flex-column-6 my-7">
            <h1 className="h1">
              {intl.locale === "en"
                ? `Empowering people with independence and self-confidence.`
                : `Menschen zu mehr Unabhängigkeit und Selbstvertrauen verhelfen.`}
            </h1>
            <p className="message-label-bold txt txt-rot">
              {intl.locale === "en"
                ? `HAL Lumbar Type (Non-Medical)`
                : `HAL Lumbar Type (Nicht-medizinisch)`}
            </p>
            <p>
              {intl.locale === "en"
                ? `HAL Lumbar Type is an assistive device on your back. HAL not only
  helps you move but reads your intention, allowing natural movement
  with motor assistance and easing the pressure on the lumbar disk.
  The motor torque can be controlled with the wearer’s thoughts
  through bioelectric signal sensing technology. HAL Lumbar Type can
  be utilized for a variety of purposes. It provides assistance to
  individuals suffering from lower back pain due to injury, aging,
  or labor-intensive jobs by reducing musculoskeletal stress.`
                : `HAL Lumbar Type ist ein Hilfsmittel auf Ihrem Rücken. HAL hilft Ihnen nicht nur dabei, sich zu bewegen, sondern erkennt auch Ihre Intention. So wird eine natürliche Bewegung mit Motorunterstützung ermöglicht und der Druck auf die Bandscheiben reduziert. Das Drehmoment der Motoren kann durch die Gedanken des Trägers mittels Ableiten biolektrischer Signale gesteuert werden. HAL Lumbar Type kann vielfältig eingesetzt werden. Er reduziert den muskoskeletalen Stress und ermöglicht Unterstützung für Personen, die an Rückenschmerzen durch Verletzungen, Alterung oder arbeitsintensive Jobs leiden. `}
            </p>
          </div>
        </div>
      </section>

      <section className="sect p-0">
        <div className="container flex-row mt-9">
          <div className="flex-column-7 pr-4">
            <h2 className="fs-11">
              {intl.locale === "en"
                ? `Two applications for one device`
                : `Zwei Anwendungsbereiche für ein Gerät`}
            </h2>
            <p className="mb-4">
              {intl.locale === "en"
                ? `HAL Lumbar Support for seniors: Creating self-confidence support
  in personal care delivery for vulnerable adults and peace of mind
  for family. From care providers to seniors, HAL Lumbar Type helps
  reduce the physical burden of lifting and moving people or heavy
  objects throughout the day as well as:`
                : `HAL Lumbar Type zur Unterstützung von Senioren Das Selbstvertrauen bei der persönlichen Pflege von hilfsbedürftigen Erwachsenen soll gestärkt werden und die Familie beruhigt werden. Vom Pflegepersonal bis hin zu Senioren hilft HAL Lumbar Type, die körperliche Belastung durch das Heben und Bewegen von Personen oder schweren Gegenständen im Tagesverlauf zu verringern:`}
            </p>
            <p className="fwb mb-2">
              {intl.locale === "en"
                ? `HAL Lumbar Support for care givers:`
                : `HAL Lumbar Unterstützung von Pflegekräfte`}
            </p>
            {intl.locale === "en" ? (
              <p className="mb-4">
                HAL Lumbar also helps wearer’s physical work, such as chair-bed
                or chair-bath transfer, help dressing and undressing, help
                bathing, rolling seniors on bed, and/or lifting or moving heavy
                objects.
                <br />
                HAL Lumbar Support is a marvel of wearable technology that
                improves, supports, and enhances body functions. The system
                senses your bioelectric signals (BES) and translates them into
                action. The device reduces the stress applied to the lumbar
                disk, thus mitigating the risk of lower back pain. You can keep
                your smile and eldery will smile back. The system also can
                provide assistance based on the leaning angle of the device
                without using BES sensors.
              </p>
            ) : (
              <p className="mb-4">
                Der HAL Lumbar Type unterstützt den Träger auch bei körperlicher
                Arbeit wie dem Transfer vom Bett in den Stuhl oder vom Stuhl ins
                Bad, beim Anziehen und Ausziehen, beim Baden, beim Drehen von
                Patienten im Bett oder beim Heben von schweren Gegenständen.
                <br />
                Der HAL Lumbar Support ist eine erstaunliche wearable
                Technologie die Körperfunktionen verbessert, unterstützt und
                steigert. Das System greift die bioelektrischen Signale des
                Trägers auf und übersetzt diese in Handlungen. Das Gerät
                reduziert die Belastung auf die Bandscheiben der
                Lendenwirbelsäule und reduziert die Gefahr von Rückenschmerzen.
                Sie können Ihr Lächeln zeigen und bekommen ein Lächeln von den
                Senioren zurück. Außerdem kann der HAL Lumbar Type die
                Bewegungsunterstützung auch basierend auf dem Beugungswinkels
                des Gerätes bereitstellen. Dafür werden keine bioelektrischen
                Sensoren benötigt
              </p>
            )}

            <p className="fwb mb-2">
              {intl.locale === "en"
                ? `HAL Lumbar Support for labor:`
                : `HAL für schwere körperliche Arbeit`}
            </p>
            {intl.locale === "en" ? (
              <p>
                HAL Lumbar Support was built with hard workers in mind. From
                agricultural workers to machine operators and construction
                workers, HAL helps reduce the physical burden of lifting and
                moving heavy objects throughout the day through BES technology.
                <br />
                The device can be put on easily and it is very easy to operate.
                There are five levels of assistance available for various tasks.
                The device conforms to strict industrial standards for the usage
                in factory environments. The device also obtained certification
                for conformance with both ISO13482 standards, which is an
                international safety standard for personal care robots and
                European Machinery Directive, which assures its safety.
              </p>
            ) : (
              <p>
                Der HAL Lumbar wurde, mit fleißigen Arbeitern im Hinterkopf,
                entwickelt. Von Arbeitskräften in der Landwirtschaft über
                Maschinenführer bis hin zu Bauarbeitern, kann der HAL mit Hilfe
                bioelektrischer Signale die körperliche Anstrengung beim Heben
                und Bewegen von schweren Gegenständen reduzieren.
                <br />
                Das Gerät kann einfach angelegt werden und ist einfach zu
                bedienen. Es gibt fünf Level der Unterstützung für verschiedene
                Aufgaben. Der HAL Lumbar stimmt mit den strengen Standards zur
                Nutzung in Fabriken überein und hat beide ISO13482 Standards
                zertifiziert bekommen. Diese sind ein internationaler
                Sicherheitsstandard für Pflegeroboter und der europäischen
                Maschienenrichtlinie. Die Sicherheit ist somit sichergestellt.
              </p>
            )}
          </div>
          <div className="flex-column-5">
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Takes just seconds to wear and simple to use`
                : `In Sekundenschnelle anzulegen und einfach zu bedienen`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Turns strenuous jobs into easy tasks`
                : `Verwandelt anstrengende Tätigkeiten in leichte Aufgaben`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1">
              {intl.locale === "en"
                ? `Encourages the use of proper mechanics of the core through neural
  cueing exercises`
                : `Fördert die korrekte Mechanik der Körpermitte durch neurologische Rückmeldung`}
            </p>
          </div>
        </div>
      </section>

      <ComponentProductNonMedical
        image="/images/products/hal-joint/lumbar-type-slider-image-1@2x.png"
      />

      <section className="p-0 py-120px  font-white position-relative">
        <StaticImage
          src="../../images/products/non-medical/lumbar-type-spec-bg@2x.png"
          className="bg-absolute"
        />

        <div className="container">
          <div className="container__inner flex-row">
            <div className="flex-column-6">
              <h2>
                {intl.locale === "en" ? `Specifications` : `Spezifikation`}
              </h2>
              <div className="flex-row mb-4">
                <div className="flex-column-6">
                  <div className="mb-2">
                    {intl.locale === "en"
                      ? ``
                      : `HAL Lumbar Type kann bei folgenden Körpermaßen getragen werden`}
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Applicable range of height`
                        : `Körpergröße`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        (approximate)
                        <br />
                        140 - 200cm
                      </p>
                    ) : (
                      <p className="">ca. 140–200 cm</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Applicable range of height`
                        : `Gewicht`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        (approximate)
                        <br />
                        40 - 100kg
                      </p>
                    ) : (
                      <p className="">ca. 40–100 kg</p>
                    )}
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Abdominal circumference ≤ 130 cm`
                        : `Bauchumfang: ≤ 130 cm`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        Thigh circumference ≤ 80 cm
                        <br />
                        Pelvic width ≤ 42 cm
                      </p>
                    ) : (
                      <p className="">
                        Oberschenkelumfang: ≤ 80 cm
                        <br />
                        Breite des Beckens: ≤ 42 cm
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `External dimensions`
                        : `Äußere Abmessungen`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        Height: 522 mm
                        <br />
                        Width: 450 - 510mm
                        <br />
                        Depth: 292mm
                      </p>
                    ) : (
                      <p className="">
                        Höhe: 522 mm
                        <br />
                        Breite: 450–510 mm
                        <br />
                        Tiefe: 292 mm
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Weight` : `Gewicht`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `3.1kg including a battery`
                        : `3,1 kg einschließlich einer Batterie`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Operating environment`
                        : `Betriebsumgebung`}
                    </p>
                    {intl.locale === "en" ? (
                      <p className="">
                        Temperature : 0℃~40℃
                        <br />
                        Humidity : 20%~80% *<br /> (no condensation)
                      </p>
                    ) : (
                      <p className="">
                        Temperatur: 0–40 ℃
                        <br />
                        Luftfeuchtigkeit: 20 bis ca. 80 %<br /> (keine
                        Kondensation)
                      </p>
                    )}
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Power source` : `Stromquelle`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Custom battery`
                        : `eigene Batterie`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Operating time`
                        : `Betriebsdauer`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `ca. 4.5 hours`
                        : `ca. 4,5 Stunden`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Length of time to recharge`
                        : `Dauer des Aufladens`}
                    </p>
                    <p className="">
                      {intl.locale === "en" ? `ca. 2 hours` : `ca. 2 Stunden`}
                    </p>
                  </div>
                </div>
              </div>
              <p>
                {intl.locale === "en"
                  ? `Please note: Specification are varied accordingly to size of the
  device`
                  : `Bitte beachten: Die Spezifikationen können je nach Größe des Gerätes variieren`}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about our products`
              : `Erfahren Sie mehr über unsere Produkte`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  Motion Principle of HAL
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL can sensor faint BES that trickle onto the skin, process
  it, and simultaneously assist one’s desired movement with
  motors located on each hip and knee joint.`
                    : `HAL kann kleinste bioelektrische Signale auf der Haut erkennen, diese verarbeiten und gleichzeitig die beabsichtige Bewegung  durch Motoren an jedem Hüft- und Kniegelenk unterstützen. `}
                </p>
                <Link
                  to="/products/medical-device/hal-motion-principal/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lower Limb Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <Link
                  to="/products/medical-device/hal-limb/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Single Joint</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which enables intensive and repetitive training of specific joints such as elbow, knee, and ankle.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, wie zum Beispiel Ellbogen, Knie und Sprunggelenk, ermöglicht.`}
                </p>
                <Link
                  to="/products/medical-device/hal-joint/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lumbar Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                    : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindes, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit. `}
                </p>
                <Link
                  to="/products/non-medical-device/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}                </Link>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cleaning Robot`
                    : `Reinigungsroboter`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation.`
                    : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
                </p>
                <div>
                  <Link to="/products/cleaning-robot/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>CYIN</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations.`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link to="/products/communication/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Acoustic X</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie, die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_Product__footer p-0">
        <div className="container py-8 my-8 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `We Have Your Back`
                : `Wir geben Ihnen Rückhalt`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Learn how this lightweight device can help your business and life.`
                : `Erfahren Sie mehr wie dieses leichte Gerät Ihnen in Ihrem Betrieb und Leben helfen kann.`}
            </p>
            <div>
              <Link to="/contact/" className="link_button">
                {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsNonMedicalDevice;
